<script src="../../../../../dist/cfg/settings.js"></script>
<template>
  <div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
          >
            <template v-slot:body>

              <v-card>
                <v-card-title>
                  Elenco
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Cerca"
                      single-line
                      hide-details
                      v-on:input="deReloadList()"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :single-select="singleSelect"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    multi-sortNO
                    item-key="id"
                    show-selectNO
                    class="elevation-1"
                    :search="search"
                    :footer-props="footerProps"
                    :server-items-length="pagination.totalNumberOfItems"
                    @update:page="paginate"
                >
                  <template v-slot:item.created_at="{ item }">
                    <span>{{item.created_at | formatDate}}</span>
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    <span>{{item.updated_at | formatDate}}</span>
                  </template>
                  <!--                                    <template v-slot:top>
                                                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                      </template>-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon  v-permission="'user.edit'"
                             small
                             class="mr-2"
                             @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import KTCodePreview from "@/views/partials/content/CodePreview.vue";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UserService from "../../../../common/user.service";

export default {
  data() {
    return {
      loading:false,
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'param_name', value: 'param_name'},
        {text: 'param_value', value: 'param_value'},
        {text: 'created_at', value: 'created_at'},
        {text: 'updated_at', value: 'updated_at'},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      pagination: {
        //descending: !!this.$route.query.desc,
        //sortBy: this.$route.query.orderby || 'name',
        rowsPerPage: 100,
        //listSize: [10, 25, 50, 100],
        listSize: [100],
        page: 1,
        totalNumberOfItems: 0,
      },
      footerProps: {'items-per-page-options': [100]},
      items: [

      ],
      filteroptions: {
        organizations: []
      },
      filter: {
        organization_id: null
      },
    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Configurazioni", route: "/setting-admin/main/list"},
      {title: "Lista configurazioni", route: "list"}
    ]);

  },

  created () {
    this.initialize()
  },

  methods: {
    paginate(val) {
      this.pagination.page = val;
      this.reloadList();
    },
    deReloadList() {
      if (window.search_timeout) clearTimeout(window.search_timeout);
      var that = this;
      window.search_timeout = setTimeout(function () {
        that.reloadList();
      }, 800);
    },
    reloadList(){
      this.loading = true;
      let params = {
        // page: this.pagination.page,
        // limit: this.pagination.rowsPerPage,
      };

      ApiService.get('settings','?' + (new URLSearchParams(params)).toString())
          .then(({ data }) => {
            this.pagination.totalNumberOfItems = data.count;
            this.items = data;
            this.loading = false;
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    initialize () {
      this.reloadList();
    },


    editItem (item) {
      this.$router.push({ name: "setting-admin-main-edit", 'params':{'id':item.id} });
    },



  },
};
</script>
